.carousel {
  width: 278px;
  height: 475px;
  overflow: hidden;
  border-radius: 36px;

  div {

    img {
      width: 100%;
      height: 100%;

    }
  }
}